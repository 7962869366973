import {
  addPaymeCart,
  confirmFulfillmentOrder,
  confirmOrder,
  confirmPayme,
  confirmPaymeCart_2,
  payme,
  submitCart,
  updateCart
} from '@/src/apis'
import { banking, qrCode } from '@/src/assets'
import { Footer, Header, Modal } from '@/src/components'
import { APP_INFO, CARRIER_MODEL, DEV_MODE, FAKE_PHONE_PAYME, ID_CART, PAYMENT_TYPE_MODEL } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { formatMoney, isCheckEmptyBill, showToast, useLocalStorage } from '@/src/utils'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const TIMEOUT_PAYME = 60000 // 60 giây

const TEST_PAYME = 'test123'
const TEST_PAYCODE_PAYME = 'ATM_POS'
const STATUS_PAYME = 'SUCCEEDED'

const PaymentMethodDetail: React.FC = () => {
  const { t } = useTranslation()
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  const [isModalErrorPayment, setIsModalErrorPayment] = useState<boolean>(false)
  const [isPayment, setIsPayment] = useState<boolean>(false)

  // @ts-ignore
  const { cart, onLoading, onSetOrder, order, onClearCart, isLoading } = useApp()
  const { setUserInfo, setPhone, onSetBill, bill, userInfo } = useUser()
  const { RFID, setRFID } = useRFID()

  const { methodId } = useParams()
  const navigate = useNavigate()
  const onUpdateInfoCart = async () => {
    try {
      const arrEPCs = []

      for (let i = 0; i < RFID.length; i += 3) {
        const epcs = RFID.slice(i, i + 3).map((item: any) => item.tag)
        arrEPCs.push({
          key: `x-haravan-epcs-0${Math.floor(i / 3) + 1}`,
          value: epcs.toString()
        })
      }

      if (!arrEPCs) return

      const paymentPaymeId = cart?.paymentMethods?.find((e: any) => e.name.indexOf('Payme') > -1)?.id

      if (!paymentPaymeId) return

      let attributes = [...cart.attributes, ...arrEPCs]

      if (isCheckEmptyBill(bill)) {
        const billAttributes = Object.entries(bill)
          .map(([key, value]) => ({ key, value }))
          .filter((e) => e.value !== '')

        attributes = [...attributes, ...billAttributes]
      }
      const payload = {
        phone: userInfo?.phone || FAKE_PHONE_PAYME,
        paymentMethodId: paymentPaymeId,
        attributes,
        locationId: appInfo?.location?.id,
        fullName: userInfo?.name || '',
        customerId: userInfo?.key || ''
      }

      await updateCart(cart.id, payload)
    } catch (err) {
      console.log(err)
    }
  }

  const onCheckout = async () => {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Timeout: Checkout process took too long'))
      }, TIMEOUT_PAYME)
    })

    try {
      let order = null
      onLoading(true)
      setIsPayment(true)
      await Promise.race([
        (async () => {
          await onUpdateInfoCart()

          if (devMode?.isDisablePayme) {
            // TẮT CẤU HÌNH THANH TOÁN PAYME => TRICK XỬ LÝ PAYME = API KHÔNG CẦN THIẾT BỊ VẬT LÝ PAYME
            const cartResponse = await submitCart({
              locationId: appInfo?.location?.id,
              screenType: 1
            })

            if (cartResponse.is_error) return
            order = cartResponse?.data?.data?.order

            // CHECKOUT PAYME
            const payload = {
              order_id: order?.id,
              amount: order?.total,
              order_number: order?.orderName,
              payCode: TEST_PAYCODE_PAYME,
              username: TEST_PAYME
            }
            const paymeResponse = await payme(payload)

            if (paymeResponse?.is_error) {
              showToast('warn', t('toast.paymentPaymeFailed'))
              navigate('/home')
              onClearTransaction()
              
            } else {
              // CONFIRM PAYME
              const { partnerTransaction, amount } = paymeResponse.data.data
              const confirmPayload = {
                partnerTransaction,
                amount,
                state: STATUS_PAYME,
                payCode: paymeResponse.data.data.payCode
              }
              await confirmPayme(confirmPayload)
            }
          } else {
            const cartResponse = await submitCart({
              locationId: appInfo?.location?.id,
              screenType: 1
            })

            const paymentPaymeId = cartResponse?.data?.data?.paymentMethods?.find(
              (e: any) => e.name.indexOf('Payme') > -1
            )?.id

            const paymeResponse = await addPaymeCart(
              cart.id,
              paymentPaymeId,
              cartResponse?.data?.data?.total,
              `${methodId}:${appInfo?.paymeCode}`
            )

            if (paymeResponse?.is_error || paymeResponse?.data?.hasError) {
              showToast('warn', t('toast.paymentPaymeFailed'))
              navigate('/home')
              onClearTransaction()
            }

            order = paymeResponse?.data?.order
          }

          const orderResponse = await confirmPaymeCart_2(order?.id)

          onSetOrder(orderResponse)
          await confirmOrder(order?.id)
          await onFulfillment(orderResponse)
        })(),
        timeoutPromise
      ])

      onLoading(false)
      navigate('/payment-completed')
      setIsPayment(false)
    } catch (error: any) {
      console.log('error', error)
      onLoading(false)
      setIsModalErrorPayment(true)
      setIsPayment(false)
    }
  }

  const onFulfillment = async (order: any) => {
    const fulfillPayload = {
      carrierShippingService: CARRIER_MODEL,
      order: {
        ...order,
        ...PAYMENT_TYPE_MODEL,
        orderProducts: null,

        locationId: appInfo?.location?.id || '',
        assignedLocationId: appInfo?.location?.id || '',
        totalWeight: order.totalWeight || 0
      },
      isSentEmail: true,
      listDetail: order?.orderProducts
    }
    await confirmFulfillmentOrder(order?.orderId, fulfillPayload)
  }

  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  const onClearTransaction = async () => {
    setIdCart(null)
    onSetOrder(null)
    onClearCart()
    setRFID([])
    setUserInfo(null)
    setPhone('')
    onSetBill()
  }

  const onActionLeft = () => {
    setIsModalErrorPayment(!isModalErrorPayment)
    navigate('/home')
    onClearTransaction()
  }

  return (
    <div className='layout pb-40'>
      <Header title='paymentMethod.payment' />

      {(() => {
        switch (methodId) {
          case 'VIETQR':
            return <QRCode total={total} />
          case 'ATM_POS':
          case 'CREDIT_POS':
            return <POS total={total} />
          default:
            return null
        }
      })()}

      <Modal
        title={'paymentMethod.paymentFail'}
        isModal={isModalErrorPayment}
        txtLeft={'close'}
        onActionLeft={onActionLeft}
      />

      {!isPayment && <Footer onNext={onCheckout} nextTitle='confirm' />}
    </div>
  )
}
//viewChildren
const QRCode: React.FC<any> = ({ total }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <>
      <p className='responsive-text-small mt-40'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text-small mt-20 text-center mx-20'>{t('paymentMethod.scanQrCodeInPosMachine')}</p>

      <img style={{ marginTop: '40px', height: '800px' }} src={qrCode} alt='img' />
    </>
  )
}

const POS: React.FC<any> = ({ total }) => {
  const { cart } = useApp()
  const { t } = useTranslation()

  return (
    <>
      <p className='responsive-text-small mt-40'>
        {t('paymentMethod.amountPayableFor')} {total} {t('paymentMethod.items')}
      </p>

      <p className='mt-8 ml-2 responsive-text font-bold	content-danger'>{formatMoney(cart?.total)}</p>

      <p className='responsive-text-small mt-20 text-center mx-20'>
        {t('paymentMethod.usePosMachineToCompletePayment')}
      </p>

      <img style={{ marginTop: '40px', height: '800px', width: '1200px' }} src={banking} alt='img' />
    </>
  )
}

export default PaymentMethodDetail
