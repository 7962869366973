import { IAppInfoLocalStorage } from '@/src/types'
import { getAppInfoFromLocalStorage } from '@/src/utils'
import { GET_API, POST_API, PUT_API } from '../configApi'

const URL_ORDER = 'admin/com_api/api/orders'

export const getCustomer = async (phone: string): Promise<any> => {
  return await GET_API({ endpoint: `${URL_ORDER}/customers/simple?query=${phone}` })
}
export const getOrder = async (orderId: string): Promise<any> => {
  return await GET_API({ endpoint: `${URL_ORDER}/${orderId}` })
}

export const confirmOrder = async (orderId: string): Promise<any> => {
  return await PUT_API({ endpoint: `${URL_ORDER}/${orderId}/confirm` })
}

export const confirmFulfillmentOrder = async (orderId: string, payload: any): Promise<any> => {
  return await POST_API({ endpoint: `${URL_ORDER}/${orderId}/fulfillment_async`, data: payload })
}

export const payme = async (payload: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/api/payme/create-payment`, data: payload })
}

export const confirmPayme = async (payload: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/api/payme/ipn`, data: payload, isIncludeAuthorization: false })
}

export const getPrintTemplate = async (orderId: number, templateId: number): Promise<any> => {
  return await POST_API({ endpoint: `admin/retail_api/api/orders/${orderId}/template/${templateId}/print` })
}

export const comitHiGD = async (payload: any): Promise<any> => {
  const appInfo: IAppInfoLocalStorage | null = getAppInfoFromLocalStorage()

  if (!appInfo?.higd) {
    console.error('No IP addresses found in local storage.')
    return
  }

  const { ip_1, ip_2 } = appInfo?.higd
  const ips = [ip_1, ip_2]
  const fallbackIP = 'https://f7c3-118-69-66-191.ngrok-free.app'

  for (const [index, ip] of ips.entries()) {
    if (ip.trim() === '') continue // Skip empty IPs

    try {
      const response = await POST_API({
        endpoint: `admin/api/https_proxy/higd?higd_url=http://${ip}/api/Security/commit`,
        data: payload
      })

      if (!response.is_error && response.data.code === 0) {
        console.log(`API call to ${ip} was successful:`, response.data.msg)
        return // STOP if successful
      } else {
        console.warn(`API call to ${ip} returned an error:`, response.message)
      }
    } catch (error) {
      console.error(`API call to ${ip} failed:`, error)
    }

    // If all IPs fail, call fallbackIP
    if (index === ips.length - 1) {
      await POST_API({
        endpoint: `admin/api/https_proxy/higd?higd_url=${fallbackIP}/api/Security/commit`,
        data: payload
      })
    }
  }
}
