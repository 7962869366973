import { comitHiGD, getPrintTemplate } from '@/src/apis'
import { complete, loading_checkout } from '@/src/assets/'
import { Header, PrintOrder } from '@/src/components'
import { APP_INFO, DEV_MODE, ID_CART } from '@/src/constants'
import { PRINT_ORDER_DETAIL } from '@/src/constants/payment'
import { useApp, useRFID, useUser } from '@/src/context'
import { IAppInfoLocalStorage, IDevModeLocalStorage } from '@/src/types'
import { printHTML, useLocalStorage } from '@/src/utils'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
const TIME_OUT_PAYMENT_COMPLETE = 60000

const Completed: React.FC = () => {
  const { t } = useTranslation()

  const isInitialMount = useRef(true)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)
  // @ts-ignore
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)

  const { onSetOrder, order, onClearCart } = useApp()
  const { setUserInfo, onSetBill, setPhone } = useUser()
  const { RFID, setRFID } = useRFID()

  const [isComplete, setIsComplete] = useState(false)
  const [printTemplate, setPrintTemplate] = useState<any>(null)

  const htmlRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      onLoadData()
      isInitialMount.current = false
    }
  }, [order])

  useEffect(() => {
    if (htmlRef.current) onPrint()
  }, [printTemplate])

  const onPrintTemplate = async () => {
    if (devMode?.isDisablePrinter) return
    setPrintTemplate(order)
  }

  const onCompleteCheckout = async () => {
    try {
      await onPrintTemplate()

      setIsComplete(true)
      setIdCart(null)
      onSetOrder(null)
      onClearCart()
      setRFID([])
      setUserInfo(null)
      setPhone('')
      onSetBill()
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmitHiGD = async () => {
    if (devMode?.isDisableHigd) return
    const payload = {
      storeName: appInfo?.location?.name || '',
      deviceCode: appInfo?.deviceCode || '',
      securityEpc: RFID.map((item: any) => ({ EPC: item.tag, Flag: 0 })), //Flag :0:epc, 1:barcode
      orderType: 1 //Status（1：receive；2：return）
    }

    await comitHiGD(payload)
  }

  const onLoadData = async () => {
    await onSubmitHiGD()
    await onCompleteCheckout()
  }

  const onPrint = async () => {
    if (htmlRef.current === null) return

    const dataPrint = htmlRef.current
    dataPrint.classList.remove('absolute')
    dataPrint.classList.remove('-z-10')

    await printHTML(dataPrint)
  }

  return (
    <div className='layout pb-40 bg-white static'>
      <Header title='paymentCompleted.payment' />
      {!isComplete ? (
        <img style={{ height: '48rem', marginTop: '5rem' }} src={loading_checkout} alt='img' />
      ) : (
        <>
          <p className='responsive-text-small mt-40'>{t('paymentCompleted.paymentComplete').toUpperCase()}</p>

          <div className='flex flex-col justify-center items-center'>
            <p className='responsive-text-small mt-20 text-center '>
              {t('paymentCompleted.dontForgetToTakeReceiptAndBelonging')}
            </p>

            <img style={{ height: '512px', width: '768px', marginTop: '5rem' }} src={complete} alt='img' />

            <p className='responsive-text-small mt-20 text-center'>{t('paymentCompleted.thankYouForShoppingWithUs')}</p>
            <p className='responsive-text-small text-center mt-2'>{t('paymentCompleted.lookFarwardToVisitAgain')}</p>
          </div>

          {printTemplate && (
            <div className='absolute -top-[200rem] -z-10'>
              <div className='w-[540px]' ref={htmlRef}>
                <PrintOrder order={printTemplate} location={appInfo?.location} />
              </div>
            </div>
          )}
        </>
      )}

      {isComplete && <Footer />}
    </div>
  )
}

//viewChildren
const Footer: React.FC<any> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      // sau thời gian 60s, auto back màn hình home
      navigate('/home')
    }, TIME_OUT_PAYMENT_COMPLETE)

    return () => clearTimeout(timer)
  }, []) // Empty dependency array ensures this runs only once

  const onBack = () => {
    navigate('/home')
  }

  return (
    <div className='fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-center'>
      <button onClick={onBack} className='bg-white w-2/6	py-6 rounded-lg border-2 border-[#0047BB] '>
        <p className='responsive-text-small-2 content-accent700'>{t('paymentCompleted.close')}</p>
      </button>
    </div>
  )
}

export default Completed
