import { getCustomer } from '@/src/apis'
import { flagVn } from '@/src/assets'
import { Header } from '@/src/components'
import { KEY_BOARD_NUMBER_2, PHONE_REGEX } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { showToast } from '@/src/utils'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './styles.css'
interface IFooter {
  onSkip?: () => void
  onNext?: () => void
  nextTitle?: string
  isDisabledNext?: boolean
  isNext?: boolean
}

const LoginPhone: React.FC = () => {
  const { t } = useTranslation()

  const { setUserInfo, phone, setPhone } = useUser()
  const { onClearCart, onLoading } = useApp()
  const { setRFID, setIsReadingRFID } = useRFID()
  const divRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  const isValidPhoneNumber = async () => {
    const isValid = PHONE_REGEX.test(phone)

    if (!isValid) {
      divRef?.current?.classList.add('animate-shake')
      await new Promise((resolve) => setTimeout(resolve, 500)) // Remove the class after animation duration
      divRef?.current?.classList.remove('animate-shake')
      showToast('warn', t('toast.invalidPhoneNumber'))
    }
    return isValid
  }

  const _onClearCart = () => {
    onClearCart()
    setRFID([])
    setIsReadingRFID(false)
  }

  const onNext = async () => {
    if (!(await isValidPhoneNumber())) return
    setPhone(phone)
    onLoading(true)

    let res = await getCustomer(phone)

    if (!res?.false) {
      setUserInfo(res?.data?.data?.data[0])
      _onClearCart()
      navigate('/shop')
    }
    onLoading(false)
  }

  const onSkip = () => {
    _onClearCart()
    navigate('/shop')
  }

  // const nextTitle = useMemo(() => {
  //   return phone ? 'next' : 'skip'
  // }, [phone])

  return (
    <div className='layout pt-40'>
      <Header title='loginPhone.member' />

      <div className={'w-4/6 flex flex-col gap-4 mt-10'}>
        <p className={'responsive-text-small'}>{t('loginPhone.inputPhoneNumber')}</p>
        <KeyboardNumber phone={phone} setPhone={setPhone} divRef={divRef} />
      </div>
      <Footer onNext={onNext} onSkip={onSkip} nextTitle={'next'} />
    </div>
  )
}

//viewChildren

const KeyboardNumber: React.FC<any> = ({ phone, setPhone, divRef }) => {
  const { t } = useTranslation()

  const onClick = (val: string) => {
    if (val === 'backspace') {
      setPhone(phone.slice(0, -1))
    } else if (val === 'reset') {
      setPhone('')
    } else {
      if (phone?.length > 30) return
      setPhone(phone + val)
    }
  }

  const isBold = (val: string) => {
    let temp = false
    if (val === 'reset' || val === 'backspace') {
      temp = true
    }

    return temp
  }

  return (
    <>
      <div
        ref={divRef}
        className={
          'w-full py-9 px-8 border-2 border-blue-500 focus:ring-blue-500 focus:border-blue-500 rounded-lg flex items-center mt-4 gap-4'
        }
      >
        <img style={{ height: '2.5rem' }} src={flagVn} alt={'flagVn'} />

        {phone ? (
          <p className={'responsive-text-small-2'}>{phone}</p>
        ) : (
          <p className={'text-[#9ca3af] responsive-text-small-2'}>{t('loginPhone.enterMobileNumber')}</p>
        )}
      </div>
      <div className='w-full flex flex-wrap gap-x-5 gap-y-2 mt-10'>
        {KEY_BOARD_NUMBER_2?.map((val, index) => {
          if (val === 'br') {
            return <div key={index} className='w-full'></div> // Tạo dòng mới
          } else {
            return (
              <button
                onClick={() => onClick(val)}
                key={index}
                type='button'
                className={`flex-grow ${isBold(val) ? `bg-[#E2E7EF]` : `bg-[#F6F7FB]`} py-8 rounded-[16px] btn-custom-input`}
              >
                {val === 'backspace' && <i className='material-icons responsive-text-small'>backspace</i>}
                {val === 'reset' && <i className='material-icons responsive-text-small'>clear</i>}
                {val !== 'backspace' && val !== 'reset' && <p className='responsive-text-small'>{val}</p>}
              </button>
            )
          }
        })}
      </div>
    </>
  )
}
// @ts-ignore
const Footer: React.FC<IFooter> = ({ onNext, nextTitle, onSkip, isDisabledNext, isNext = true }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const onBack = () => {
    navigate('/home')
  }

  return (
    <div className='z-50 fixed bottom-0 w-full flex bg-white border-t-2 py-4 px-20 justify-between'>
      <button onClick={onBack} className='button-prev footer'>
        <p className='content-accent700 responsive-text-small-2'>{t('back')}</p>
      </button>
      <button onClick={onSkip}>
        <p className='content-accent700 responsive-text-small-2 border-b-2 border-blue-500'>{t('skip')}</p>
      </button>
      <button onClick={onNext} className='button-next footer'>
        <p className='text-white responsive-text-small-2'>{t(nextTitle || 'confirm')}</p>
      </button>
    </div>
  )
}

export default LoginPhone
