import { filterByBarcode, getCart, updateCart } from '@/src/apis'
import { Footer, Header, Modal } from '@/src/components/'
import { DEV_MODE, ID_CART } from '@/src/constants'
import { useApp, useRFID, useUser } from '@/src/context'
import { IDevModeLocalStorage } from '@/src/types'
import { formatMoney, isCheckEmptyBill, uniqueEpcs, useLocalStorage } from '@/src/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { compareQuantities, compareRFIDArrays, groupDataByBarcode } from '../shop/utils'
import './styles.css'

const TABLE_HEADER = [
  {
    id: 0,
    title: 'NO.',
    key: 'cart.no'
  },
  {
    id: 1,
    title: 'SKU',
    key: 'cart.skuNo'
  },
  {
    id: 2,
    title: 'Item Name',
    key: 'cart.itemName'
  },
  {
    id: 3,
    title: 'Size',
    key: 'cart.size'
  },
  {
    id: 4,
    title: 'Price',
    key: 'cart.price'
  },
  {
    id: 5,
    title: 'Quantity',
    key: 'cart.quantity'
  },
  {
    id: 6,
    title: 'Total Price',
    key: 'cart.totalPrice'
  }
]

const Cart: React.FC = () => {
  const { cart } = useApp()
  // @ts-ignore
  const [devMode, setDevMode] = useLocalStorage<IDevModeLocalStorage | null>(DEV_MODE, null)
  // @ts-ignore
  const [idCart, setIdCart] = useLocalStorage<string | null>(ID_CART, null)

  const location = useLocation()
  const { isValidateRFID } = location.state || {} // Lấy product từ state

  const isInitialMount = useRef(true)
  const { setIsReadingRFID, onReadRFID, RFID, setRFID } = useRFID()
  const { onLoading, onSetCart, onClearCart, onSetOrder } = useApp()
  const { setUserInfo, onSetBill, bill, setPhone } = useUser()

  // STATE
  const [isInvoice, setIsInvoice] = useState(isCheckEmptyBill(bill) || false)
  const [isModalConfirmCart, setIsModalConfirmCart] = useState<boolean>(false)
  const [isModalReAddCart, setIsModalReAddCart] = useState<boolean>(false)
  const [isModalErrorRFID, setIsModalErrorRFID] = useState<boolean>(false)

  const [isReCheckcartSuccess, setIsReCheckcartSuccess] = useState<boolean>(false) // sau khi re-check cart xong, thì mới cho thưc thi tiếp các actions, tránh conflict

  const navigate = useNavigate()

  function isCheckValidateRFID(arr: { tag: string; barcode: string }[]): boolean {
    return arr.every((item) => item.barcode !== '')
  }

  const onNext = async () => {
    if (isInvoice && !isCheckEmptyBill(bill)) {
      navigate('/payment-invoice')
    } else {
      if (devMode?.isDisableRFID) {
        navigate('/payment-method')
      } else {
        if (!isReCheckcartSuccess) return
        // KHI THANH TOÁN, SẼ DOUBLE CHECK SẢN PHẨM

        // check RFID
        onLoading(true)

        let newRFID = await onReadRFID()

        let isValidateCart = await compareRFIDArrays(newRFID, RFID)
        if (isValidateCart) {
          setIsModalConfirmCart(true)
        } else {
          setIsModalReAddCart(true)
        }
        onLoading(false)
      }
    }
  }

  const onBack = () => {
    navigate('/login-phone')
  }

  const onRecheckCart = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500))

    let tags = await onReadRFID()

    let isValidRFID = isCheckValidateRFID(tags)
    if (isValidRFID) {
      if (tags?.length > 0) {
        let newRFID = tags.filter((item_new: any) => !RFID.some((item_old: any) => item_old.tag === item_new.tag))
        newRFID = uniqueEpcs(newRFID)

        if (newRFID?.length > 0) {
          const barcodes = newRFID?.map((item: any) => item.barcode)
          let product = await filterByBarcode(barcodes)

          let dataByBarcode = groupDataByBarcode(newRFID)

          // FILTER VARIANT_ID BY BARCODE PRODUCT
          const barcodeToVariantId = product.data.data.data?.reduce((acc: any, product: any) => {
            product.variants.forEach((variant: any) => {
              acc[variant.barcode] = variant.id
            })
            return acc
          }, {})

          // FILTER LINEITEMS => PAYLOAD ADD TO CART
          const lineItems: any[] = dataByBarcode
            .map((item: any) => {
              const variantId = barcodeToVariantId[item.barcode]
              return {
                variantId,
                quantity: item.qty
              }
            })
            .filter((item: any) => item.variantId)

          //DÙNG API ĐỂ LẤY THÔNG TIN GIỎ HÀNG
          let newCart = await getCart()

          // KIỂM TRA SỐ LƯỢNG SẢN PHẨM
          // NẾU SP ĐÃ TỒN TẠI THÌ CỘNG SỐ LƯỢNG LÊN
          lineItems.forEach((newItem) => {
            const oldItem = newCart?.data?.lineItems.find((old: any) => old.variantId === newItem.variantId)
            if (oldItem) {
              newItem.quantity += oldItem.quantity // Cộng số lượng của newItem với oldItem
            }
          })

          let payload = {
            lineItems
          }

          let res = await updateCart(newCart?.data?.id, payload)

          if (res) {
            await onSetCart()
            setRFID([...RFID, ...newRFID])

            const isValidateRFID = compareQuantities(dataByBarcode, lineItems)
            if (!isValidateRFID) {
              setIsModalErrorRFID(true)
            }
          }
        }
      }

      setIsReCheckcartSuccess(true) // sau khi re-check cart xong, thì mới cho thưc thi tiếp các actions, tránh conflict
    } else {
      setIsModalErrorRFID(true)
    }
  }

  const onConfirmCart = async () => {
    setIsModalConfirmCart(!isModalConfirmCart)
    navigate('/payment-method')
  }

  const onReAddCart = async () => {
    setRFID([])
    onClearCart()
    setIsReadingRFID(false)
    //
    navigate('/shop')
  }

  const onClearTransaction = async () => {
    setIdCart(null)
    onSetOrder(null)
    onClearCart()
    setRFID([])
    setUserInfo(null)
    setPhone('')
    onSetBill()

    //
    setIsModalErrorRFID(!isModalErrorRFID)
    navigate('/home')
  }

  const isDisabledBtnNext = useMemo(() => {
    return isModalErrorRFID
  }, [isValidateRFID, isModalErrorRFID])

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      if (!devMode?.isDisableRFID) {
        onRecheckCart() // SAU KHI ADD cart thành công, kiểm tra add cart thêm lân nữa để tránh trường hợp bị thiếu sản phẩm
      }
      isInitialMount.current = false
    }
  }, [])

  useEffect(() => {
    if (!isValidateRFID) setIsModalErrorRFID(true)
  }, [])

  return (
    <div className='pb-16 w-full'>
      <Header title='cart.listOfGoods' />
      {cart?.lineItems?.length > 0 && (
        <>
          <TableHeader />
          <TableBody cart={cart} />

          <Amount isInvoice={isInvoice} setIsInvoice={setIsInvoice} />
          <div className='h-96' />
        </>
      )}

      <Footer onNext={onNext} onBack={onBack} isDisabledNext={isDisabledBtnNext} />

      <Modal
        title={'shop.confirmOrder'}
        isModal={isModalConfirmCart}
        txtLeft={'back'}
        onActionLeft={() => setIsModalConfirmCart(!isModalConfirmCart)}
        txtRight={'confirm'}
        onActionRight={onConfirmCart}
      />

      <Modal title={'shop.reAddCart'} isModal={isModalReAddCart} txtRight={'confirm'} onActionRight={onReAddCart} />

      <Modal title={'shop.rfidError'} isModal={isModalErrorRFID} txtLeft={'close'} onActionLeft={onClearTransaction} />
    </div>
  )
}

//viewChildren
const TableHeader: React.FC<any> = () => {
  const { t } = useTranslation()

  const width = (title: string) => {
    let temp = 'w-1/6'
    switch (title) {
      case 'NO.':
      case 'Quantity':
      case 'Size':
        temp = 'w-1/12'
        break
      case 'SKU':
      case 'Price':
      case 'Total Price':
        temp = 'w-2/12'
        break
      case 'Item Name':
        temp = 'w-3/12'
        break
      default:
        break
    }

    return temp
  }
  //
  return (
    <>
      <div className='fixed top-28 w-full flex border-b border-[#E5E5E5] py-4 bg-[#F6F7FB] '>
        {TABLE_HEADER?.map((val, index) => {
          return (
            <p key={index} className={`responsive-text-small-3 text-black  ${width(val.title)} text-center`}>
              {t(val.key)}
            </p>
          )
        })}
      </div>
      <div className='h-24' />
    </>
  )
}

const TableBody: React.FC<any> = ({ cart }) => {
  return (
    <>
      {cart?.lineItems?.length > 0 && (
        <div className={'w-full'}>
          {cart?.lineItems?.map((val: any, index: number) => {
            return (
              <div key={index}>
                <Item item={val} index={index} isLast={index === cart?.lineItems?.length - 1} />
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

const Item: React.FC<any> = ({ item, index, isLast }) => {
  const isDicount = useMemo(() => {
    return item?.priceOriginal > item?.linePrice
  }, [item])

  return (
    <div className={`responsive-text-small-4 flex w-full py-4  border-[#E5E5E5] border-b`}>
      <p className={'responsive-text-small-4 flex w-1/12 flex justify-center items-center'}>{index + 1}</p>
      <p className={'responsive-text-small-4 flex w-2/12 flex justify-center items-center break-all'}>{item.barcode}</p>
      <p className={'responsive-text-small-4 flex w-3/12 flex justify-center items-center text-center break-all px-4'}>
        {item.productTitle}
      </p>
      <p className={'responsive-text-small-4 flex w-1/12 flex justify-center items-center  text-center break-all'}>
        {item.variantTitle.split('/')[1]}
      </p>
      <div className='responsive-text-small-4 flex flex-col w-2/12 justify-center items-center break-all'>
        {isDicount ? (
          <>
            <p>{formatMoney(item.linePrice)}</p>
            <p className='line-through'>{formatMoney(item.price)}</p>
          </>
        ) : (
          <p>{formatMoney(item.price)}</p>
        )}
      </div>
      <p className={'responsive-text-small-4 flex w-1/12 flex justify-center items-center  text-center break-all'}>
        {item.quantity}
      </p>
      <div className={'responsive-text-small-4 flex flex-col w-2/12 flex justify-center items-center break-all '}>
        {isDicount ? (
          <>
            <p>{formatMoney(item.linePrice * item.quantity)}</p>
            <p className='line-through'>{formatMoney(item.price * item.quantity)}</p>
          </>
        ) : (
          <p>{formatMoney(item.price * item.quantity)}</p>
        )}
      </div>
    </div>
  )
}

const Amount: React.FC<any> = ({ isInvoice, setIsInvoice }) => {
  const { onSetBill } = useUser()

  const { t } = useTranslation()

  const { cart } = useApp()

  const handleChange = () => {
    setIsInvoice(!isInvoice)
    onSetBill()
  }

  const total = useMemo(() => {
    return cart?.lineItems?.reduce((sum: number, item: any) => sum + item.quantity, 0) ?? 0
  }, [cart])

  return (
    <div className='bg-white flex flex-col fixed  w-full justify-end items-end p-8 mb-4 amount'>
      <div className='flex gap-2 justify-end items-end	'>
        <p className='responsive-text-small-3 mb-1'>
          {total} {t('cart.items')}, {t('cart.total')}:
        </p>
        <p className='responsive-text-small font-bold content-danger'>{formatMoney(cart?.total)}</p>
      </div>
      <div className='flex items-center space-x-4'>
        <input
          type='checkbox'
          id='checkbox'
          checked={isInvoice}
          onChange={handleChange}
          className='h-8 w-8 text-blue-600 border-gray-300 rounded focus:ring-blue-500'
        />
        <label htmlFor='checkbox' className='text-gray-700'>
          <p className='responsive-text-small-3 mt-2'>{t('paymentInvoice.einvoiceInformation')}</p>
        </label>
      </div>
    </div>
  )
}

export default Cart
