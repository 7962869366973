import { formatDate } from '@/src/utils'
import React, { useMemo } from 'react'
import Barcode from 'react-barcode'

interface IModal {
  order: any
  location: any
}

const formatMoney = (input: number): string => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  })

  // Replace the currency symbol (₫) and any space before it
  return formatter.format(input).replace(/\./g, ',').replace(/\s?₫/, '')
}

const TABLE_HEADER = [
  {
    id: 0,
    title: 'ITEM'
  },
  {
    id: 1,
    title: 'QTY'
  },
  {
    id: 2,
    title: 'PRICE'
  },
  {
    id: 3,
    title: 'AMOUNT'
  }
]

const PrintTemplate: React.FC<IModal> = ({ order, location }) => {
  return (
    <div className='w-full pb-8'>
      <Header order={order} location={location} />
      <Body order={order} />
      <Footer order={order} />
    </div>
  )
}
//viewChildren

const Header: React.FC<any> = ({ order, location }) => {
  const locationAdress = useMemo(() => {
    const { locationName, wardName, districtName, provinceName } = location
    const parts = [locationName, wardName, districtName, provinceName].filter((part) => part)

    return parts.join(' , ')
  }, [location])

  return (
    <>
      {/* INFO */}
      <div className='w-full flex flex-col items-center justify-center gap-4 pt-8'>
        <img
          src='https://file.hstatic.net/1000284478/file/maison_ecom_avatar_4c8ff9b1a44543e6ac98887915a2c0cc.png'
          width={'200px'}
          alt=''
        />
        <p className='text-3xl font-bold'>{location?.locationName}</p>
        <p className='text-2xl font-bold'>MAISON RMI GROUP</p>
      </div>
      <div className='mt-4 flex flex-col'>
        <p className='text-2xl'>
          <b>Địa chỉ :</b> {locationAdress}
        </p>
        <p className='text-2xl'>
          <b>Số điện thoại :</b> {location?.phone}
        </p>
      </div>
      {/* TITLE */}
      <div className='border-dashed border-y-2 border-black mt-4 pb-6 pt-2'>
        <p className='text-2xl font-bold text-center'>INVOICE</p>
      </div>
      <div className='flex flex-col'>
        <p className='text-2xl'>
          <b>INVOICE NO :</b> {order?.orderNumber}
        </p>
        <p className='text-2xl'>
          <b>DATE TIME :</b> {formatDate(order?.createdDate)}
        </p>
        <p className='text-2xl'>
          <b>CUSTOMER ID :</b> {order?.customerInfo?.id}
        </p>
        <p className='text-2xl'>
          <b>NAME :</b> {order?.customerInfo?.fullname || 'WALK-IN'}
        </p>
      </div>
    </>
  )
}
const Body: React.FC<any> = ({ order }) => {
  const { orderProducts } = order

  const width = (title: string) => {
    let temp = 'w-1/6'
    switch (title) {
      case 'ITEM':
        temp = 'w-4/12'
        break
      case 'QTY':
        temp = 'w-2/12'
        break
      case 'PRICE':
      case 'AMOUNT':
        temp = 'w-3/12'
        break

      default:
        break
    }

    return temp
  }

  const textCenter = (title: string) => {
    let temp = 'w-1/6'
    switch (title) {
      case 'ITEM':
        temp = 'text-start'
        break
      case 'QTY':
      case 'PRICE':
        temp = 'text-center'
        break
      case 'AMOUNT':
        temp = 'text-end'
        break

      default:
        break
    }

    return temp
  }

  const variant = (item: any, symbol: string) => {
    let temp = item?.split(' / ')
    return `${temp[0]} ${temp[1] ? `${symbol} ${temp[1]}` : ''}`
  }

  const grossTotal = useMemo(() => {
    return orderProducts?.reduce((total: number, item: any) => {
      return total + item.quantity * item.originalPrice
    }, 0)
  }, [orderProducts])

  const transactions = useMemo(() => {
    return order.transactions.filter(
      (transaction: any) => transaction.transactionName === 'Xác nhận thanh toán' || transaction.type === 'paid'
    )
  }, [order])

  const totalItems = useMemo(() => {
    return orderProducts?.reduce((total: number, item: any) => {
      return total + item.quantity
    }, 0)
  }, [orderProducts])

  return (
    <div>
      {/* HEADER */}
      <div className='flex border-dashed border-y-2 border-black mt-8 pb-6 pt-2'>
        {TABLE_HEADER?.map((_val, _index) => {
          return (
            <p key={_index} className={`text-2xl ${width(_val.title)} ${textCenter(_val.title)} font-bold`}>
              {_val.title}
            </p>
          )
        })}
      </div>
      {orderProducts?.map((val: any, index: number) => {
        return (
          <div key={index} className='border-dashed border-b-2 border-black pb-8'>
            {/* BODY */}
            <div className='flex gap-4 mt-4'>
              <p className={`text-2xl ${width('ITEM')} break-all`}>{val.barcode}</p>
              <p className={`text-2xl ${width('QTY')} ${textCenter('QTY')}`}>{val.quantity}</p>
              <p className={`text-2xl ${width('PRICE')} ${textCenter('PRICE')} break-all`}>
                {formatMoney(val.productPrice)}
              </p>
              <p className={`text-2xl ${width('AMOUNT')} ${textCenter('AMOUNT')} break-all`}>
                {formatMoney(val.totalPrice)}
              </p>
            </div>
            <p className={`text-2xl`}>{variant(val.variantValue, '-')}</p>
            <p className={`text-2xl`}>
              {val.productTypeName}, {variant(val.variantValue, ',')}
            </p>
            {/* DISCOUNT */}
            {val?.discountAllocations?.length > 0 && (
              <div className='flex flex-col gap-2'>
                {val?.discountAllocations?.map((_val: any, _index: number) => {
                  return (
                    <div key={_index} className='flex justify-between'>
                      <p className={'text-2xl'}>{_val.code}</p>
                      <p className={'text-2xl'}>-{formatMoney(_val.amount)}</p>
                    </div>
                  )
                })}
                {/* PRICE */}
                <div className='flex justify-between'>
                  <p className={'text-2xl'}>ITEM TOTAL :</p>
                  <p className={'text-2xl'}>{formatMoney(val.totalPrice)}</p>
                </div>
              </div>
            )}

            <div className='flex justify-between mt-2'>
              <p className={'text-2xl'}>FULL PRICE :</p>
              <p className={'text-2xl'}>{formatMoney(val.originalPrice * val.quantity)}</p>
            </div>
          </div>
        )
      })}

      {/* FOOTER */}
      {order?.redeemUsedAmount > 0 && (
        <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
          <p className={'text-2xl'}>LOYALTY COIN : </p>
          <p className={'text-2xl'}>{formatMoney(order?.redeemUsedAmount)}</p>
        </div>
      )}

      <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
        <p className={'text-2xl'}>GROSS TOTAL : </p>
        <p className={'text-2xl'}>{formatMoney(grossTotal)}</p>
      </div>
      <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
        <p className={'text-2xl'}>NET TOTAL : </p>
        <p className={'text-2xl'}>{formatMoney(order?.orderTotal)}</p>
      </div>
      {transactions?.length > 0 && (
        <div className='flex justify-between border-dashed border-b-2 border-black pb-6 pt-2'>
          {transactions?.map((val: any, index: number) => {
            return (
              <div key={index} className='flex justify-between'>
                <p className={'text-2xl w-2/3 break-all'}>
                  {val.paymentMethodName === 'Tiền mặt' ? 'CASH' : val.paymentMethodName} :
                </p>
                <p className={'text-2xl w-1/3 text-end'}>{formatMoney(val.amountTransaction)}</p>
              </div>
            )
          })}
        </div>
      )}
      {/* COUNT ITEM */}
      <div className='flex flex-col border-dashed border-b-2 border-black  pb-6 pt-2 gap-4'>
        <div className='flex justify-between'>
          <p className={'text-2xl '}>NUMBER OF ITEMS</p>
          <p className={'text-2xl'}>{orderProducts?.length}</p>
        </div>
        <div className='flex justify-between'>
          <p className={'text-2xl '}>TOTAL ITEMS</p>
          <p className={'text-2xl'}>{totalItems}</p>
        </div>
      </div>
    </div>
  )
}

const Footer: React.FC<any> = ({ order }) => {
  return (
    <>
      <div className='flex justify-center item-center border-dashed border-b-2 border-black  pb-6 pt-2 gap-4'>
      <img
          src='https://file.hara.vn/200000014460/file/untitled-1.jpg?v=1724923192'
          alt=''
          style={{ maxWidth: '100%' }}
        />
      </div>
      <div className='flex flex-col mt-2 gap-2'>
        <p className='text-2xl text-center '>
          Đổi hàng nguyên giá trong 30 ngày kể từ ngày mua theo chính sách Maison. Không hoàn tiền. Chi tiết điều khoản
          áp dụng vui lòng xem tại:
        </p>
        <p className='text-2xl text-center'>https://maisonrmi.com/faqs/</p>
        <div className='flex justify-center items-center'>
          <Barcode value={order?.orderId.toString()} displayValue={false} />
        </div>
      </div>
    </>
  )
}

export default PrintTemplate
